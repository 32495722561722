<template>
  <v-data-table
    :headers="headers"
    :items="items"
    disable-pagination
    :loading="isLoading"
    sort-by="used"
  />
</template>

<script>
import en from '@/i18n/en'
import fr from '@/i18n/fr'
import pt from '@/i18n/pt'
import es from '@/i18n/es'
import it from '@/i18n/it'

export default {
  data () {
    return ({
      headers: [
        { text: 'key', value: 'k' },
        { text: 'en', value: 'en' },
        { text: 'fr', value: 'fr' },
        { text: 'pt', value: 'pt' },
        { text: 'it', value: 'it' },
        { text: 'es', value: 'es' },
        { text: 'used', value: 'used' }
      ],
      isLoading: true,
      items: []
    })
  },
  async mounted () {
    try {
      const e = await this.$http().get('/core/maintenance/vue-translation')
      const keys = this.lodash.uniq(Object.keys(en).concat(Object.keys(fr)).concat(Object.keys(pt)).concat(Object.keys(es)).concat(Object.keys(it)).concat(e.data), v => v)

      this.items = keys.map(k => ({ k, en: en[k], fr: fr[k], it: [k], es: [k], pt: [k], used: e.data.includes(k) }))
    } finally {
      this.isLoading = false
    }
  }
}
</script>
